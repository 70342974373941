import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LangToggle from '../../components/LangToggle';
import BackButton from '../../components/BackButton';

import styles from './style.module.css';
import { useLocation } from 'react-router';

const Header = ({
  locale,
  setLocale
}) => {
  const [isBack, setIsBack] = useState(false);
  const location = useLocation();

  useEffect(() => {
    (location.pathname === '/' || location.pathname === '/finish' || location.pathname === '/qr')
      ? setIsBack(false)
      : setIsBack(true);
  }, [location]);

  const innerClassName = [styles.inner, 'container'].join(' ');

  return (
    <header className={styles.header}>
      <div className={innerClassName}>
        {
          isBack
            ? <BackButton />
            : null
        }
        <LangToggle
          className={styles.lang}
          locale={locale}
          setLocale={setLocale}
        />
      </div>
    </header>
  );
};

Header.propTypes = {
  isBack: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired
};

export default Header;
