import React, {useEffect } from 'react';
import Calendar from 'react-calendar';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import '../../assets/styles/calendar.css';

import iconPrev from '../../assets/images/icon-chevron-left.svg';
import iconNext from '../../assets/images/icon-chevron-right.svg';
import { FormattedMessage } from 'react-intl';

const TerminalCalendar = ({
  locale,
  dates,
  setSelectedDate,
  message
}) => {
  const setEnabledDates = date =>
    dates
    && !dates
      .some(item => {
        return DateTime.fromSQL(item.date).toMillis() === DateTime.fromJSDate(date).toMillis();
      });

  useEffect(() => {
    if (dates && dates.length > 0) {
      setSelectedDate(DateTime.fromSQL(dates[0].date).toSQLDate());
    }
  }, [dates]);

  return (
    <>
      {
        dates
          ? <Calendar
            className='terminal-calendar'
            locale={locale === 'ru' ? 'ru-RU' : 'uz-Cyrl-UZ'}
            defaultValue={DateTime.fromSQL(dates[0].date).toJSDate()}
            minDate={DateTime.fromSQL(dates[0].date).toJSDate()}
            maxDate={DateTime.fromSQL(dates[dates.length - 1].date).toJSDate()}
            minDetail='month'
            nextLabel={<img src={iconNext} />}
            prevLabel={<img src={iconPrev} />}
            next2Label={null}
            prev2Label={null}
            tileDisabled={({ date }) => setEnabledDates(date)}
            onClickDay={(value) => setSelectedDate(DateTime.fromJSDate(value).toSQLDate())}
          />
          : <h2><FormattedMessage id={message} /></h2>
      }
    </>
  );
};

TerminalCalendar.propTypes = {
  locale: PropTypes.string.isRequired,
  dates: PropTypes.array,
  setSelectedDate: PropTypes.func.isRequired,
  message: PropTypes.string
};

export default TerminalCalendar;
