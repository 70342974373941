import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import styles from './style.module.css';

const NextButton = ({
  className,
  isDisabled,
  onClick
}) => {
  return (
    <button
      className={className + ' ' + styles.button}
      disabled={isDisabled}
      onClick={onClick}
      type='button'
    >
      <FormattedMessage id='buttonNext' />
    </button>
  );
};

NextButton.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default NextButton;
