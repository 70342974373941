import React, { useRef, useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import PropTypes from 'prop-types';

import './keyboard.css';
import 'react-simple-keyboard/build/css/index.css';

const numericLayout = {
  default: ['1 2 3', '4 5 6', '7 8 9', ' 0 ']
};

const terminalLayout = {
  default: [
    'й ц у к е н г ш щ з х ъ',
    'ф ы в а п р о л д ж э',
    '{shift} я ч с м и т ь б ю {shift}',
    '\' {space} -'
  ],
  shift: [
    'Й Ц У К Е Н Г Ш Щ З Х Ъ',
    'Ф Ы В А П Р О Л Д Ж Э',
    '{shift} Я Ч С М И Т Ь Б Ю {shift}',
    '\' {space} -'
  ]
};

const TerminalKeyboard = ({
  onChange,
  isNumeric
}) => {
  const [layout, setLayout] = useState('default');
  const [keyBoardInstance, setKeyBoardInstance] = useState(null);
  const keyboard = useRef();

  const onKeyboardChange = input => {
    onChange(input);

    keyBoardInstance.clearInput();
  };

  const handleShift = () => {
    const newLayoutName = layout === 'default' ? 'shift' : 'default';
    setLayout(newLayoutName);
  };

  const onKeyPress = button => {
    if (button === '{shift}') handleShift();
  };

  const keyBoardTheme = isNumeric ? 'numeric-keyboard' : 'terminal-keyboard';

  return (
    <div>
      <Keyboard
        keyboardRef={r => (keyboard.current = r)}
        layoutName={layout}
        layout={isNumeric ? numericLayout : terminalLayout}
        onInit={(kb) => { setKeyBoardInstance(kb); }}
        onChange={onKeyboardChange}
        onKeyPress={onKeyPress}
        maxLength={10}
        buttonTheme={[
          {
            class: 'hg-disable',
            buttons: ' '
          }
        ]}
        theme={keyBoardTheme}
      />
    </div>
  );
};

TerminalKeyboard.propTypes = {
  onChange: PropTypes.func,
  isNumeric: PropTypes.bool,
};

export default TerminalKeyboard;
