import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import ErrorMessage from '../ErrorMessage';

import styles from './style.module.css';

const ConfirmCodeField = ({
  isError,
  value
}) => {  
  const inputClassName = classNames({
    [styles.input]: true,
    [styles.error]: isError
  });

  const renderFields = useCallback(() => {
    const fields = Array.from({ length: 5 }, (el, i) => {
      const className = inputClassName + ' ' + (value.length === i ? styles.nextInput : '');
      return <input className={className} key={i} type='text' maxLength={1} readOnly value={value[i] || ''} />;
    });

    return fields;
  }, [value, isError]);

  return (
    <div className={styles.field}>
      {
        isError
          ? <ErrorMessage>
            {/* Неверный код, попробуйте еще раз */}
            <FormattedMessage id='confirmError' />
          </ErrorMessage>
          : null
      }
      {
        renderFields()
      }
    </div>
  );
};

ConfirmCodeField.propTypes = {
  fieldLength: PropTypes.number,
  isError: PropTypes.bool,
  value: PropTypes.string
};

export default ConfirmCodeField;
