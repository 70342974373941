import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';

import { pluralize } from '../../utils';

import styles from './style.module.css';

const MAX_TIME = 300;

const MINUTE = 60;

const Timer = ({
  isTimer,
  setIsTimer
}) => {
  const [remainingTime, setRemainingTime] = useState(MAX_TIME);
  const [currentWidth, setCurrentWidth] = useState(0);

  const history = useHistory();

  const intl = useIntl();

  useEffect(() => {
    if (isTimer) {
      const start = Date.now();

      const timer = setInterval(function () {
        var delta = ((Date.now() - start) / 1000);
        if (delta <= MAX_TIME) {
          setRemainingTime(MAX_TIME - delta);
          setCurrentWidth( delta / MAX_TIME * 100);
        } else {
          clearInterval(timer);
          setIsTimer(false);
          history.push('/finish', { isSuccess: false });
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isTimer]);

  return (
    <div className={styles.timer}>
      <div className='container'>
        <div className={styles.wrapper}>
          <p className={styles.text}>
            {/* Для подтверждения записи осталось:&nbsp; */}
            <FormattedMessage id='timer' />
            {
              remainingTime > MINUTE
                ? <span>
                  {Math.ceil(remainingTime / 60)} {pluralize([intl.formatMessage({ id: 'timeMinute1' }), intl.formatMessage({ id: 'timeMinute2' }), intl.formatMessage({ id: 'timeMinute3' })], Math.ceil(remainingTime / 60))}
                </span>
                : <span>
                  {Math.ceil(remainingTime)} {pluralize([intl.formatMessage({ id: 'timeSecond1' }), intl.formatMessage({ id: 'timeSecond2' }), intl.formatMessage({ id: 'timeSecond3' })], Math.ceil(remainingTime))}
                </span>
            }
          </p>
          <div className={styles.progressbar} style={{ width: `${currentWidth}%` }}></div>
        </div>
      </div>
    </div>
  );
};

Timer.propTypes = {
  isTimer: PropTypes.bool.isRequired,
  setIsTimer: PropTypes.func.isRequired,
};

export default Timer;
