import React from 'react';
import PropTypes from 'prop-types';

const TicketPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      {props.children}
    </div>
  );
});
TicketPrint.displayName = 'TicketPrint';

TicketPrint.propTypes = {
  children: PropTypes.any.isRequired
};

export default TicketPrint;

