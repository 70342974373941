import React, { useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import { LOCALES } from '../i18n/locales';
import { messages } from '../i18n/messages';

import Header from '../layout/Header';
import ConfirmCode from '../pages/ConfirmCode';
import FinishPage from '../pages/FinishPage';
import Phone from '../pages/Phone';
import StartPage from '../pages/StartPage';
import Timer from './Timer';
import VisitDate from '../pages/Visitdate';
import NamePage from '../pages/NamePage';
import PromoPage from '../pages/PromoPage';
import ErrorModal from './ErrorModal';
import BirthDate from '../pages/BirthDate';
import City from '../pages/City';

const App = () => {
  const [isTimer, setIsTimer] = useState(false);
  const [locale, setLocale] = useState(LOCALES.RUSSIAN);
  const [user, setUser] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [criticalError, setCriticalError] = useState('');

  const logOut = (history) => {
    localStorage.removeItem('mmctoken');
    if (history) {
      history.push('/');
    }
    window.location.assign('/?rnd=' + new Date().getTime());
  };

  return (
    <IntlProvider messages={messages[locale]} locale={locale} defaultLocale='ru'>
      <div className='container'>
        <BrowserRouter>
          <Header
            locale={locale}
            setLocale={setLocale}
          />
          <Switch>
            <Route
              path='/'
              exact
              render={(props) => <StartPage setIsTimer={setIsTimer} setUser={setUser} setUserProfile={setUserProfile} {...props} />}
            />
            <Route
              path='/phone'
              exact
              render={(props) => <Phone locale={locale} user={user} setUser={setUser} setError={setCriticalError} {...props} />}
            />
            <Route
              path='/confirmcode'
              exact
              render={(props) => <ConfirmCode locale={locale} user={user} setUser={setUser} setError={setCriticalError} {...props} />}
            />
            <Route
              path='/name'
              exact
              render={(props) => <NamePage locale={locale} userProfile={userProfile} setUserProfile={setUserProfile} setError={setCriticalError} {...props} />}
            />
            <Route
              path='/birthdate'
              exact
              render={(props) => <BirthDate locale={locale} user={user} userProfile={userProfile} setUserProfile={setUserProfile} setError={setCriticalError} {...props} />}
            />
            <Route
              path='/city'
              exact
              render={(props) => <City locale={locale} userProfile={userProfile} setUserProfile={setUserProfile} setError={setCriticalError} {...props} />}
            />
            <Route
              path='/visitdate'
              exact
              render={(props) => <VisitDate locale={locale} userProfile={userProfile} setUserProfile={setUserProfile} setError={setCriticalError} {...props} />}
            />
            <Route
              path='/finish'
              exact
              render={(props) => <FinishPage locale={locale} userProfile={userProfile} setUser={setUser} setUserProfile={setUserProfile} logOut={logOut} setIsTimer={setIsTimer} {...props} />}
            />
            <Route
              path='/qr'
              exact
              render={(props) => <PromoPage logOut={logOut} {...props} />}
            />
          </Switch>
          {
            isTimer
              ? <Timer
                locale={locale}
                isTimer={isTimer}
                setIsTimer={setIsTimer}
                logOut={logOut}
              />
              : null
          }
          {
            criticalError
              ? <ErrorModal
                setError={setCriticalError}
              >
                {criticalError}
              </ErrorModal>
              : null
          }
        </BrowserRouter>
      </div>
    </IntlProvider>
  );
};

export default App;
