import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.css';

import iconClose from '../../assets/images/icon-cross.svg';

const ErrorModal = ({
  onClick,
  children,
  setError
}) => {
  return (
    <div className={styles.modal} onClick={() => { onClick ? onClick() : null; setError(false); }}>
      <div className={styles.content}>
        <div className={styles.close}>
          <img src={iconClose} />
        </div>
        {children}
      </div>
    </div>
  );
};

ErrorModal.propTypes = {
  onClick: PropTypes.func,
  setError: PropTypes.func.isRequired,
  children: PropTypes.any
};

export default ErrorModal;
