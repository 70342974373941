import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { DateTime } from 'luxon';

import Page from '../../layout/Page';
import NextButton from '../../components/NextButton';
import ErrorMessage from '../../components/ErrorMessage';
import TerminalKeyboard from '../../components/TerminalKeyboard';

import { formatPhoneNumber } from '../../utils';
import { fetchUserProfile } from '../../api/fetchUserProfile';

import styles from './style.module.css';

import iconDelete from '../../assets/images/icon-delete.svg';
import { FormattedMessage } from 'react-intl';
import { IMaskInput } from 'react-imask';
import { useEffect } from 'react';

const BIRTH_DAY_LENGTH = 10;

const BirthDate = ({
  locale,
  user,
  userProfile,
  setUserProfile,
  setError
}) => {
  const [birthDate, setBirthDate] = useState(userProfile.birthDate || '__.__.____');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!isNaN(DateTime.fromFormat(birthDate, 'dd.MM.yyyy').toMillis())
      && DateTime.fromFormat(birthDate, 'dd.MM.yyyy') > DateTime.local().minus({ days: 1 })) {
      setIsError(true);
      setBirthDate(birthDate.slice(0, birthDate.length - 1) + '_');
    } else if (!isNaN(DateTime.fromFormat(birthDate, 'dd.MM.yyyy').toMillis())
      && DateTime.fromFormat(birthDate, 'dd.MM.yyyy') < DateTime.local().minus({ days: 1 })) {
      setIsError(false);
    } else if (!birthDate.includes('_') && isNaN(DateTime.fromFormat(birthDate, 'dd.MM.yyyy').toMillis())) {
      setIsError(true);
    }
  }, [birthDate]);

  const history = useHistory();

  const onDateChange = input => {
    setBirthDate(birthDate.replace('_', input));
  };

  const onDeleteButtonClick = () => {
    if (birthDate.length > 0) {
      setIsError(false);
      setBirthDate(birthDate.replace(/\d(?=\D*$)/, '_'));
    }
  };

  const postUserData = useCallback(() => {
    const userBirthDate = {
      birthdate: DateTime.fromFormat(birthDate, 'dd.MM.yyyy').toSQLDate()
    };

    if (userBirthDate.birthdate > DateTime.local().toSQLDate()) {
      console.log('here');
      setIsError(true);
      return;
    }

    if (userProfile.firstName && userProfile.lastName) {
      const userData = {
        lastName: userProfile.lastName,
        firstName: userProfile.firstName,
        middleName: userProfile.middleName,
        dateOfBirth: userBirthDate.birthdate,
        identityDocumentSeries: '',
        identityDocumentNumber: '',
        phone: formatPhoneNumber(user.phone),
        plannedArrivalDate: DateTime.now().toISODate(),
        inRussia: true,
      };

      fetchUserProfile(locale, userData)
        .then((response) => {
          setUserProfile({ ...userProfile, ...{ birthDate }, ...{ uuid: response.data.data.uuid } });
          history.push('/city');
        })
        .catch(err => {
          if (err.response && err.response.data.success === false) {
            setIsError(false);
            setError(err.response.data.errors[0].message);
          } else {
            setError(err.message);
          }
        });
    } else {
      setIsError(true);
    }
  }, [locale, birthDate]);

  return (
    <Page
      // title='Введите дату рождения'
      title={<FormattedMessage id='dateTitle' />}
    >
      <form className={styles.birthDateForm}>
        <div className={styles.birthDateWrapper}>
          <div className={styles.messageWrapper}>
            {
              isError
                ? <ErrorMessage>
                  <FormattedMessage id='dateError' />
                </ErrorMessage>
                : null
            }
            <IMaskInput
              className={styles.date}
              mask={Date}
              radix='.'
              unmask={false}
              onAccept={
                (value) => {
                  setBirthDate(value);
                }
              }

              max={DateTime.now().minus({ days: 1 }).toJSDate()}

              value={birthDate}
              lazy={false}
              autofix={true}
              readOnly
            />
          </div>
          <button className={styles.deleteButton} type='button' onClick={onDeleteButtonClick}>
            <img src={iconDelete} alt='Удалить' />
          </button>
        </div>
        <TerminalKeyboard
          onChange={onDateChange}
          isNumeric
        />
        <NextButton
          className={styles.nextButton}
          isDisabled={(birthDate.length !== BIRTH_DAY_LENGTH || birthDate.includes('_') || isError)}
          onClick={postUserData}
        >
          Далее
        </NextButton>
      </form>
    </Page>
  );
};

BirthDate.propTypes = {
  locale: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  setUserProfile: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
};

export default BirthDate;
