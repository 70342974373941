import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './style.module.css';

const NavButton = ({
  to,
  type = 'start',
  isDisable = false,
  className,
  onClick,
  children
}) => {
  const navClassName = classNames(
    styles.button,
    styles[type],
    className,
    { [styles.disabled]: isDisable }
  );

  return (
    <div>
      {
        isDisable
          ? <span
            className={navClassName}
          >
            {children}
          </span>
          : <NavLink
            to={to}
            className={navClassName}
            activeClassName='active'
            onClick={!isDisable && onClick}
          >
            {children}
          </NavLink>
      }
    </div>
  );
};

NavButton.propTypes = {
  to: PropTypes.string.isRequired,
  type: PropTypes.string,
  isDisable: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any
};

export default NavButton;
