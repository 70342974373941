import axios from 'axios';

const API_URL = process.env.REACT_APP_API;

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

if (localStorage.getItem('mmctoken')) {
  headers.Authorization = localStorage.getItem('mmctoken');
}

export const defaultAPIOptions = {
  responseType: 'json',
  headers
};

export const api = axios.create({
  baseURL: API_URL,
  ...defaultAPIOptions
});
