/**
 * Format `name` string to `Name`
 * @param {String} name - string with name
 * @returns formatted string where the first letter is uppercase and the other letters are lowercase
 */
export function formatName(name) {
  if (name) {
    return name[0].toUpperCase() + name.slice(1).toLowerCase();
  }
}

/**
 * Removes extra characters from the phone number
 * @param {String} phone - phone number as a string
 * @returns a string containing only numbers and +
 */
export function formatPhoneNumber(phone) {
  return phone.replace(/[^\d.+]/g, '');
}

/**
 * Outputs the word corresponding to the number
 * @param {Array} forms - array with posibble forms of the word
 * @param {Number} val - value related to the word
 * @returns The correct form of the word depending on the value
 */
export function pluralize(forms, val) {
  const cases = [2, 0, 1, 1, 1, 2];
  return forms[(val % 100 > 4 && val % 100 < 20) ? 2 : cases[(val % 10 < 5) ? val % 10 : 5]];
}


export const validateName = (str) => {
  return (/^[а-яА-ЯёЁa-zA-Z]+(([' -][а-яА-ЯёЁa-zA-Z ])?[а-яА-ЯёЁa-zA-Z]*)*$/).test(str);
};

// ^[a-zA-Z]+(([' -][a-zA-Z ])?[a-zA-Z]*)*$
// ^[[а-яА-ЯёЁa-zA-Z]+['-\s]?]+$
