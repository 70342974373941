import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import NavButton from '../../components/NavButton';

import styles from './style.module.css';

import logo from '../../assets/images/logo-mmc.svg';
import { useHistory } from 'react-router-dom';
import ErrorModal from '../../components/ErrorModal';

const StartPage = ({
  setIsTimer,
  setUser,
  setUserProfile
}) => {
  const location = useLocation();
  const history = useHistory();
  const [sourceMessage, setSourceMessage] = useState();
  const [source, setSource] = useState();
  const [isCheckingMessage, setIsCheckingMessage] = useState(false);

  useEffect(() => {
    location.pathname === '/'
      ? setIsTimer(false)
      : null;

    setUser({});
    setUserProfile({});

    const query = new URLSearchParams(location.search);
    const setSourceParam = query.get('setSource');
    const getSourceParam = query.get('getSource');
    const setPrinterParam = query.get('setPrinter');
    const getPrinterParam = query.get('getPrinter');

    if (setSourceParam !== null) {
      localStorage.setItem('source', setSourceParam);
      setSourceMessage(setSourceParam
        ? 'Параметр местоположения установлен в значение '
        : 'Параметр местоположения не установлен, по умолчанию будет отправлено значение '
      );
      setSource(setSourceParam
        ? `«${setSourceParam}»`
        : '«moscow_mmc»'
      );
      setIsCheckingMessage(true);
    }

    if (getSourceParam !== null) {
      const sourceValue = localStorage.getItem('source');

      setSourceMessage(sourceValue
        ? 'Параметр местоположения установлен в значение '
        : 'Параметр местоположения не установлен, по умолчанию будет отправлено значение '
      );
      setSource(sourceValue
        ? `«${sourceValue}»`
        : '«moscow_mmc»'
      );
      setIsCheckingMessage(true);
    }

    if (setPrinterParam !== null) {
      localStorage.setItem('printer', setPrinterParam);

      setSourceMessage('Параметр печати в приложении установлен в ');
      setSource(setPrinterParam);
      setIsCheckingMessage(true);
    }

    if (getPrinterParam !== null) {
      const printerValue = localStorage.getItem('printer');

      setSourceMessage(printerValue
        ? 'Параметр печати на терминале установлен в значение '
        : 'Параметр печати на терминале не установлен'
      );

      setSource(
        printerValue
          ? printerValue
          : ''
      );

      setIsCheckingMessage(true);
    }
  }, [location]);

  return (
    <div className={styles.page}>
      <div className={styles.logo}>
        <img className={styles.img} width={681} height={152} src={logo} alt="" />
      </div>
      <h1 className={styles.title}>
        {/* Записаться в  миграционный центр г. Москвы и Московской области можно здесь */}
        <FormattedMessage id='startMessage' />
      </h1>
      <NavButton
        to='/phone'
        onClick={() => { setIsTimer(true); }}
      >
        {/* Подать документы на патент */}
        <FormattedMessage id='buttonSubmitDocs' />
      </NavButton>
      {
        isCheckingMessage
          ? <ErrorModal
            onClick={() => history.push('/')}
            setError={setIsCheckingMessage}
          >
            <p>{sourceMessage}<strong>{source}</strong></p>
          </ErrorModal>
          : null
      }
    </div>
  );
};

StartPage.propTypes = {
  setIsTimer: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  setUserProfile: PropTypes.func.isRequired
};

export default StartPage;
