import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';
import QRCode from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
import { DateTime } from 'luxon';

import NavButton from '../../components/NavButton';
import TicketPrint from '../../components/TicketPrint';
import NextButton from '../../components/NextButton';

import { fetchPin } from '../../api/fetchPin';

import styles from './style.module.css';

import iconSuccess from '../../assets/images/icon-success.svg';
import iconTimeout from '../../assets/images/icon-timeout.svg';
import classNames from 'classnames';

const DELAY = 120;

const FinishPage = ({
  locale,
  userProfile,
  logOut,
  setUser,
  setUserProfile,
  setIsTimer
}) => {
  const location = useLocation();
  const history = useHistory();

  const [isSuccess, setIsSuccess] = useState(location.state.isSuccess || true);
  const [isPinSend, setIsPinSend] = useState(true);
  const [isResend, setIsResend] = useState(false);
  const [printerStatus] = useState(localStorage.printer);
  const finishClassName = [styles.page, !isSuccess && styles.timeout]
    .filter(Boolean)
    .join(' ');

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    location.pathname === '/finish'
      ? setIsTimer(false)
      : null;

  }, [location]);

  useEffect(() => {
    if (printerStatus === 'on') {
      handlePrint();
    }
  }, [printerStatus, location]);


  useEffect(() => {
    if (location.state) {
      setIsSuccess(location.state.isSuccess);
    } else {
      setIsTimer(false);
    }
  }, [location]);

  useEffect(() => {
    if (location?.state?.isSuccess) {
      fetchPin(locale, userProfile.serviceOrderUuid)
        .then(() => {
          setIsPinSend(true);
        })
        .catch(() => {
          setIsPinSend(false);
        });
    } else {
      setUser({});
      setUserProfile({});
    }
  }, [location.state]);

  useEffect(() => {
    let exitTimeout = null;
    exitTimeout = setTimeout(() => {
      logOut(history);
    }, DELAY * 1000);
    return () => clearTimeout(exitTimeout);
  });

  const sendPinClassName = classNames(
    styles.sendPinStatus,
    {
      [styles.successSend]: isPinSend
    }
  );

  return (
    <div className={finishClassName}>
      <div className={styles.icon}>
        {
          isSuccess
            ? <img src={iconSuccess} alt='Успешно' />
            : <img src={iconTimeout} alt='Время вышло' />
        }
      </div>
      <div className={styles.message}>
        {
          isSuccess
            ? <>
              {
                printerStatus === 'on'
                  ? <div className={styles.successMessge}>
                    <TicketPrint ref={printRef} >
                      <div className={styles.printBlock}>
                        <p className={styles.text}>Код для записи:</p>
                        <p className={styles.pin}>{userProfile.pin}</p>
                        <QRCode
                          className={styles.qr}
                          value={'74923327'}
                          size={256}
                          bgColor={'#ffffff'}
                          fgColor={'#000000'}
                          level={'L'}
                          includeMargin={false}
                          renderAs={'svg'}
                        />
                      </div>
                    </TicketPrint>
                    <p className={styles.text}>
                      {/* Вы успешно записаны на подачу документов: */}
                      <FormattedMessage id='finishSuccess' />
                    </p>
                    <p className={styles.text}>
                      {/* Код для записи распечатан на чеке. */}
                      <FormattedMessage id='finishPrint' />
                      <br />
                      <FormattedMessage id='finishTakeCode' />
                    </p>
                  </div>
                  : <div className={styles.successMessge}>
                    <p className={styles.text}>
                      {/* Вы успешно записаны на подачу документов: */}
                      <FormattedMessage id='finishSuccess' />
                    </p>
                    {
                      userProfile.serviceDate
                        ? <p className={styles.time}>
                          {DateTime.fromSQL(userProfile.serviceDate.date).setLocale(locale === 'ru' ? 'ru-RU' : 'uz-Cyrl-UZ').toFormat('dd MMMM yyyy')} в {userProfile.serviceDate.startTime}
                        </p>
                        : null
                    }
                    <p className={styles.text}>
                      {/* Код для записи отправлен вам в СМС. */}
                      <FormattedMessage id='finishSms' values={{ pin: <b>{userProfile.pin}</b> }} />
                      <br />
                      {/* Возьмите его с собой. */}
                      <FormattedMessage id='finishTakeCode' />
                    </p>
                  </div>
              }
            </>
            : <p className={styles.timeoutMessage}>
              {/* Время вышло, попробуйте сначала */}
              <FormattedMessage id='finishTimeout' />
            </p>
        }
      </div>
      <div className={styles.buttonGroup}>
        {
          isSuccess
            ?
            <NextButton
              className={styles.nextButton}
              onClick={() => history.push('/qr')}
            >
              Далее
            </NextButton>
            : <>
              <NavButton
                to='/phone'
                type='start'
                className={styles.restart}
                onClick={() => setIsTimer(true)}
              >
                {/* Начать сначала */}
                <FormattedMessage id='buttonStartAgain' />
              </NavButton>
              <NavButton
                to='/'
                type='finish'
                onClick={() => logOut(history)}
              >
                {/* На главную */}
                <FormattedMessage id='buttonToMain' />
              </NavButton>
            </>
        }
      </div>
      {
        isSuccess
          ?
          <div className={sendPinClassName}>
            <p>
              {
                isPinSend
                  ? (
                    null
                  ) : (
                    <>
                      {
                        !isResend ? (
                          // {/* Ошибка при отправке СМС, попробуйте еще раз */}
                          <FormattedMessage id='finishSendFail' />
                        ) : (
                          // {/* СМС не отправлена, сфотографируйте код на экране и возьмите его с собой */}
                          <FormattedMessage id='finishSendEpicFail' />
                        )
                      }
                    </>
                  )
              }
            </p>
            {
              isPinSend
                ? null
                : isResend
                  ? null
                  : <button
                    type='button'
                    onClick={() => {
                      setIsResend(true);
                      fetchPin(locale, userProfile.serviceOrderUuid)
                        .then(() => {
                          setIsPinSend(true);
                        })
                        .catch(() => {
                          setIsPinSend(false);
                        });
                    }}
                    className={styles.sendButton}
                  >
                    {/* Отправить еще раз */}
                    <FormattedMessage id='finishSendButton' />
                  </button>
            }
          </div>
          : null
      }
    </div >
  );
};

FinishPage.propTypes = {
  locale: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  setUserProfile: PropTypes.func.isRequired,
  setIsTimer: PropTypes.func.isRequired
};

export default FinishPage;
