import { LOCALES } from './locales';

export const messages = {
  [LOCALES.RUSSIAN]: {
    buttonSubmitDocs: 'Подать документы на патент',
    buttonNext: 'Далее',
    buttonSendCode: 'Отправить код повторно',
    buttonToMain: 'На главную',
    buttonStartAgain: 'Начать сначала',
    startMessage: 'Записаться в  миграционный центр г. Москвы и Московской области можно здесь',
    timer: 'Для подтверждения записи осталось: ',
    timeMinute1: 'минута',
    timeMinute2: 'минуты',
    timeMinute3: 'минут',
    timeSecond1: 'секунда',
    timeSecond2: 'секунды',
    timeSecond3: 'секунд',
    phoneTitle: 'Введите номер телефона',
    phoneDesc: 'Мы отправим 5-ти значный код на указанный номер для проверки телефона',
    phoneError: 'Неверный номер телефона, попробуйте еще раз.',
    confirmTitle: 'Введите код из СМС',
    confirmDesc: 'Мы отправили код в SMS-сообщении на номер:<nl></nl> {phone}',
    confirmError: 'Неверный код, попробуйте еще раз',
    nameTitle: 'Введите ФИО',
    nameError: 'Введите как минимум фамилию и имя',
    visitTitle: 'Выберите дату и время посещения Миграционного центра',
    visitInterval: 'C {startTime} до {endTime}',
    finishSuccess: 'Вы успешно записаны на подачу документов:',
    finishPrint: 'Код для записи распечатан на чеке.',
    finishSms: 'Код для записи: {pin} отправлен вам в СМС.',
    finishTakeCode: 'Возьмите его с собой.',
    finishTimeout: 'Время вышло, попробуйте сначала',
    finishSendFail: 'Ошибка при отправке СМС, попробуйте еще раз',
    finishSendSuccess: 'СМС отправлена',
    finishSendEpicFail: 'СМС не отправлена, сфотографируйте код на экране',
    finishSendButton: 'Отправить еще раз',
    promoText: 'Отсканируйте QR-код и скачайте приложение<nl></nl>в <img>{icon}</img> Google Play. Отслеживайте статусы и важную информацию.',
    dateTitle: 'Введите дату рождения',
    dateError: 'Неверный формат даты, попробуйте еще раз',
    dateLoading: 'Идет загрузка подождите...',
    dateNoDates: 'Нет доступных дат',
    cityTitle: 'Выберите город, где собираетесь работать',
    commonError: 'Ошибка, повторите попытку еще раз',
    namePlaceholder: 'Фамилия Имя Отчество',
    nameValidError: 'Введены недопустимые символы',
    nameLengthError: 'Максимальная длина ФИО 96 символов'
  },
  [LOCALES.UZBEK]: {
    buttonSubmitDocs: 'Патент олиш учун ҳужжатларни топшириш',
    buttonNext: 'Кейинги',
    buttonSendCode: 'Кодни қайта юбориш',
    buttonToMain: 'Бош менюга',
    buttonStartAgain: 'Бошидан бошлаш',
    startMessage: 'Бу ерда Москва шаҳри ва Москва вилоятининг миграция маркази қабулига ёзилиш мумкин',
    timer: 'Қабулга ёзилганингизни тасдиқлаш учун қолди: ',
    timeMinute1: 'дақиқа',
    timeMinute2: 'дақиқа',
    timeMinute3: 'дақиқа',
    timeSecond1: 'сония',
    timeSecond2: 'сония',
    timeSecond3: 'сония',
    phoneTitle: 'Телефон рақамини киритинг',
    phoneDesc: 'Телефонингизни текшириш учун кўрсатилган рақамга 5 хонали код юборамиз',
    phoneError: 'Яроқсиз телефон рақами, яна уриниб кўринг.',
    confirmTitle: 'СМС дан олинган кодни киритинг',
    confirmDesc: 'Биз кодни СМС хабарда қуйидаги рақамга юбордик:<nl></nl> {phone}',
    confirmError: 'Сиз нотўғри SMS даги кодни кўрсатдингиз',
    nameTitle: 'Тўлиқ исмингизни (ФИШ) киритинг',
    nameError: 'Камида фамилиянгизни ва исмингизни киритинг',//'Биринчи ва фамилиянгизни киритинг',
    visitTitle: 'Миграция марказига ташриф буюриш санаси ва вақтини танланг',
    visitInterval: '{startTime} дан {endTime} гача',
    finishSuccess: 'Сиз ҳужжатларни топшириш учун муваффақиятли рўйхатдан ўтдингиз:',
    finishPrint: 'Рўйхатдан ўтиш коди квитансияда чоп етилади.',
    finishSms: 'Рўйхатдан ўтиш коди {pin} сизга СМС орқали юборилган.',
    finishTakeCode: 'Уни ўзингиз билан олинг.',
    finishTimeout: 'Вақт тугади, қайтадан уриниб кўринг',
    finishSendFail: 'СМС юборишда хатолик, қайтадан уриниб кўринг',
    finishSendSuccess: 'СМС юборилган',
    finishSendEpicFail: 'СМС юборилмади, екрандаги кодни расмга олинг',
    finishSendButton: 'Қайта юборинг',
    promoText: 'ҚР кодини сканерланг<nl></nl>ва <img>{icon}</img> Google Плай иловасини юклаб олинг. Track статуслар ва муҳим маълумотлар.',
    dateTitle: 'Туғилган санангизни киритинг',
    dateError: 'Нотўғри сана, яна уриниб кўринг',
    dateLoading: 'Юклаб олиш давом етмоқда кутинг...',
    dateNoDates: 'Йўқ, хурмо мавжуд',
    cityTitle: 'Ишламоқчи бўлган шаҳарни танланг',
    commonError: 'Хато, қайта уриниб кўринг',
    namePlaceholder: 'Тўлиқ исмингизни',
    nameValidError: 'Киритилган хато белгилар',
    nameLengthError: 'Тўлиқ исмнинг максимал узунлиги 96 белгидан иборат'
  },
};
