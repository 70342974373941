import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';

import NextButton from '../../components/NextButton';
import Page from '../../layout/Page';
import TerminalCalendar from '../../components/TerminalCalendar';

import { fetchMmc } from '../../api/fetchMmc';
import { fetchServiceOrder, putServicePrices } from '../../api/fetchServiceOrder';
import { getIntervals, putInterval } from '../../api/fetchIntervals';

import styles from './style.module.css';
import { getPayment } from '../../api/fetchPayment';

const VisitDate = ({
  locale,
  userProfile,
  setUserProfile,
  setError
}) => {
  const [mmc, setMmc] = useState(null);
  const [serviceOrder, setServiceOrder] = useState(null);

  const [intervals, setIntervals] = useState(null);
  const [availableDates, setAvailableDates] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [calendarStatus, setCalendarStatus] = useState('dateLoading');

  const history = useHistory();

  useEffect(() => {
    if (!mmc) {
      fetchMmc(locale)
        .then((response) => {
          if (response.data.success) {
            setMmc(response.data.data
              .filter(item => item.uuid === '83927b06-8b64-449d-9b5b-1957e2482981') // uuid московского ММЦ
              .pop());
          }
        })
        .catch((err) => {
          if (err?.response?.data?.success === false) {
            setError(err.response.data.errors[0].message);
          }
        });
    }

    if (mmc) {
      const serviceOrderRequest = {
        userProfileUuid: userProfile.uuid,
        mmcUuid: mmc.uuid,
        serviceGroupUuid: 'c7dc35c2-c63b-4b7f-8c4f-a651013d37d2', // uuid группы с type: patent
        source: localStorage.getItem('source') || 'moscow_mmc',
        city: userProfile.city
      };

      if (!serviceOrder) {
        fetchServiceOrder(locale, serviceOrderRequest)
          .then((response) => {
            if (response.data.success) {
              setUserProfile({ ...userProfile, serviceOrderUuid: response.data.data.uuid });
              setServiceOrder(response.data.data);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.success === false) {
              setError(err.response.data.errors[0]?.message);
            }
          });
      }

      if (serviceOrder) {
        putServicePrices(locale, serviceOrder.uuid, {
          servicesPricesIds: [43], // id услуги предварительная запись для московского ММЦ
        })
          .catch((err) => {
            if (err?.response?.data?.success === false) {
              setError(err.response.data.errors[0]?.message);
            }
          });
      }
    }
  }, [mmc, serviceOrder]);

  useEffect(() => {
    if (serviceOrder && !availableDates) {
      getIntervals(locale, serviceOrder.uuid)
        .then((responce) => {
          if (responce.data.success) {
            const dates = responce.data.data;
            const newDates = dates
              .filter(item =>
                DateTime.fromSQL(item.date) <= DateTime.fromSQL(dates[0].date).plus({ months: 1 })
              )
              .filter(item => item.available);
            setAvailableDates(newDates);
          } else {
            setAvailableDates(null);
            setCalendarStatus('dateNoDates');
          }
        })
        .catch((err) => {
          if (err?.response?.data?.success === false) {
            setError(err.response.data.errors[0]?.message);
          }
        });
    }
  }, [serviceOrder]);

  useEffect(() => {
    if (selectedDate && availableDates) {
      const currentDate = availableDates
        .filter(item => DateTime.fromSQL(item.date).toSQLDate() === selectedDate)
        .pop();

      let intervals = [];

      if (DateTime.fromSQL(currentDate.date).toMillis() === DateTime.now().toMillis()) {
        intervals = currentDate.intervals
          .filter(item => item.available)
          .filter(item => DateTime.fromSQL(item.startTime) > DateTime.local());
      } else {
        intervals = currentDate.intervals
          .filter(item => item.available);
      }

      setIntervals(intervals);
    }
  }, [selectedDate]);

  const putSelectedDate = useCallback(() => {
    const finishDate = { ...{ date: DateTime.fromSQL(selectedDate).toSQLDate() }, ...selectedInterval };
    putInterval(locale, serviceOrder.uuid, finishDate)
      .then((response) => {
        if (response.data.success) {
          getPayment(locale, serviceOrder.uuid)
            .then((response) => {
              if (response.data.success && response.data.data.status === 'paid') {
                setUserProfile({ ...userProfile, ...{ serviceDate: finishDate }, ...{ qrData: response.data.data.qrCodeData, pin: response.data.data.maximaQueueSlot.pin } });
                history.push('/finish', { isSuccess: true });
              } else {
                setError(<FormattedMessage id="commonError" />);
              }
            })
            .catch((err) => {
              if (err?.response?.data?.success === false) {
                setError(err.response.data.errors[0]?.message);
              } else {
                setError(<FormattedMessage id="commonError" />);
              }
            });
        }
      })
      .catch((err) => {
        if (err?.response?.data?.success === false) {
          setError(err.response.data.errors[0].message);
        }
      });
  }, [locale, selectedInterval]);

  const buttonClassName = [
    styles.timeButton,
    locale !== 'ru' && styles.smallText]
    .filter(Boolean)
    .join(' ');

  return (
    <Page
      // title='Выберите дату и время посещения Миграционного центра'
      title={<FormattedMessage id='visitTitle' />}
    >
      <div className={styles.wrapper}>
        <TerminalCalendar
          locale={locale}
          dates={availableDates}
          setSelectedDate={setSelectedDate}
          message={calendarStatus}
        />
        <div className={styles.listWrapper}>
          <ul className={styles.timeList}>
            {
              !!intervals &&
              intervals.map((item, index) =>
                <>
                  {
                    selectedInterval && item.startTime === selectedInterval.startTime
                      ? <li key={index} className={styles.timeItem}>
                        <button
                          className={buttonClassName + ' ' + styles.active}
                          type='button'
                          onClick={() => {
                            if (item?.ticketId) {
                              setSelectedInterval({ startTime: item.startTime, endTime: item.endTime, ticketId: item.ticketId });
                            } else {
                              setSelectedInterval({ startTime: item.startTime, endTime: item.endTime });
                            }
                          }}
                        >
                          <FormattedMessage id='visitInterval' values={{ startTime: item.startTime, endTime: item.endTime }} />
                        </button>
                      </li>
                      : <li key={index} className={styles.timeItem}>
                        <button
                          className={buttonClassName}
                          type='button'
                          onClick={() => {
                            if (item?.ticketId) {
                              setSelectedInterval({ startTime: item.startTime, endTime: item.endTime, ticketId: item.ticketId });
                            } else {
                              setSelectedInterval({ startTime: item.startTime, endTime: item.endTime });
                            }
                          }}
                        >
                          <FormattedMessage id='visitInterval' values={{ startTime: item.startTime, endTime: item.endTime }} />
                        </button>
                      </li>
                  }
                </>
              )
            }
          </ul>
        </div>
      </div>
      <NextButton
        className={styles.nextButton}
        isDisabled={!selectedInterval}
        onClick={putSelectedDate}
      >
        Далее
      </NextButton>
    </Page>
  );
};

VisitDate.propTypes = {
  locale: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired,
  setUserProfile: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
};

export default VisitDate;
