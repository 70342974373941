import React from 'react';
import { useHistory } from 'react-router';

import styles from './style.module.css';

import iconArrow from '../../assets/images/icon-arrow-back.svg';

const BackButton = () => {
  const history = useHistory();

  return (
    <button
      className={styles.button}
      type='button'
      onClick={() => history.goBack()}
    >
      <img src={iconArrow} alt="Вернуться назад" />
    </button>
  );
};

export default BackButton;
