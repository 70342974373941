import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.css';

const ErrorMessage = ({
  children
}) => {
  return (
    <span className={styles.message}>
      {children}
    </span>
  );
};

ErrorMessage.propTypes = {
  children: PropTypes.any.isRequired
};

export default ErrorMessage;
