import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.css';
import classNames from 'classnames';

const Page = ({
  className,
  title,
  desc,
  children
}) => {
  const pageClassName = classNames(styles.page, {[className]: className});
  return (
    <section className={pageClassName}>
      {
        title
          ? <h2 className={styles.title}>{title}</h2>
          : null
      }
      {
        desc
          // ? <p className={styles.text} dangerouslySetInnerHTML={{ __html: desc }}></p>
          ? <p className={styles.text}>{desc}</p>
          : null
      }
      {children}
    </section >
  );
};

Page.propTypes = {
  className: PropTypes.string,
  title: PropTypes.object,
  desc: PropTypes.object,
  children: PropTypes.any
};

export default Page;
