import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import TerminalKeyboard from '../../components/TerminalKeyboard';
import Page from '../../layout/Page';
import NextButton from '../../components/NextButton';
import ErrorMessage from '../../components/ErrorMessage';

import styles from './style.module.css';

import iconDelete from '../../assets/images/icon-delete.svg';
import { formatName, validateName } from '../../utils';

const NAME_MAX_LENGTH = 96;
const NAME_PART_MAX_LENGTH = 32;

const NamePage = ({
  locale,
  userProfile,
  setUserProfile,
}) => {
  const [name, setName] = useState(userProfile.fullName || '');
  const [isError, setIsError] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isValidLength, setIsValidLength] = useState(true);

  const history = useHistory();

  const onInputName = input => {
    setName(name + input);
    setIsError(false);
  };

  const onDeleteButtonClick = () => {
    if (name.length > 0) {
      setName(name.slice(0, name.length - 1));
      setIsError(false);
    } else {
      setIsError(false);
      setIsValid(true);
    }
  };

  const formatUserName = (nameValue) => {
    let nameArray = nameValue.replace(/\s+/g, ' ').trim().split(' ');

    if (nameArray.length > 0) {
      nameArray = nameArray.map(item => formatName(item));
    }

    const isValidPartLength = nameArray.length > 0 && !nameArray.some((item, index) => (index === 0 && item && item.length > NAME_MAX_LENGTH) || (index !== 0 && item && item?.length > NAME_PART_MAX_LENGTH));

    if (!isValidPartLength) {
      setIsValidLength(false);
    }

    let userName = {};

    if (nameArray.length < 2) {
      setIsError(true);
    } else if (nameArray.length < 3) {
      userName = { ...userName, ...{ fullName: nameArray.toString().replace(/,/g, ' '), lastName: nameArray[0], firstName: nameArray[1], middleName: '' } };
    } else if (nameArray.length === 3) {
      userName = { ...userName, ...{ fullName: nameArray.toString().replace(/,/g, ' '), lastName: nameArray[0], firstName: nameArray[1] }, middleName: nameArray[2] };
    } else {
      let middleName = '';
      for (let i = 2; i < nameArray.length; i++) {
        middleName += nameArray[i] + ' ';
      }
      userName = { ...userName, ...{ fullName: nameArray.toString().replace(/,/g, ' '), lastName: nameArray[0], firstName: nameArray[1], middleName: middleName.trimEnd() } };
    }

    return userName;
  };

  const setUserProfileName = useCallback(() => {

    const userName = formatUserName(name);

    if (validateName(name)) {
      if (name.length <= NAME_MAX_LENGTH) {

        if (Object.keys(userName).length !== 0 && isValidLength) {
          setUserProfile({ ...userProfile, ...userName });
          history.push('/birthdate');
        }
      } else {
        setIsValidLength(false);
      }
    } else {
      setIsValid(false);
    }
  }, [locale, name]);

  const nameClassName = classNames(
    styles.name,
    { [styles.error]: isError || !isValid || !isValidLength }
  );

  useEffect(() => {
    if (name.length <= NAME_MAX_LENGTH) {
      setIsValidLength(true);
    }

    if (validateName(name)) {
      setIsValid(true);
    }
  }, [name]);

  return (
    <Page
      // title={'Введите ФИО'}
      title={<FormattedMessage id='nameTitle' />}
    >
      <div className={styles.wrapper}>
        <div className={styles.nameWrapper}>
          <div className={styles.messageWrapper}>
            {
              isError && isValid && isValidLength
                ? <ErrorMessage>
                  <FormattedMessage id='nameError' />
                </ErrorMessage>
                : null
            }
            {
              !isValid
                ? <ErrorMessage>
                  <FormattedMessage id='nameValidError' />
                </ErrorMessage>
                : null
            }
            {
              !isValidLength
                ? <ErrorMessage>
                  <FormattedMessage id='nameLengthError' />
                </ErrorMessage>
                : null
            }
            <div className={nameClassName}>
              <span>{name}</span>
              {
                name
                  ? null
                  : <span className={styles.placeholder}>
                    <FormattedMessage id='namePlaceholder' />
                  </span>
              }
            </div>
          </div>
          <button className={styles.deleteButton} type='button' onClick={onDeleteButtonClick}>
            <img src={iconDelete} alt='Удалить' />
          </button>
        </div>
        <TerminalKeyboard
          onChange={onInputName}
        />
      </div>


      <NextButton
        className={styles.nextButton}
        isDisabled={name.length <= 2 || !isValid || !isValidLength}
        onClick={setUserProfileName}
      >
        Далее
      </NextButton>
    </Page >
  );
};

NamePage.propTypes = {
  locale: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired,
  setUserProfile: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
};


export default NamePage;
