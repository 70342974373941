import React from 'react';
import PropTypes from 'prop-types';

import { LOCALES } from '../../i18n/locales';

import styles from './style.module.css';

import uzFlag from '../../assets/images/icon-flag-uz.svg';
import ruFlag from '../../assets/images/icon-flag-ru.svg';

const LangToggle = ({
  className,
  locale,
  setLocale
}) => {
  const onClickSetLocale = () => {
    if (locale === LOCALES.RUSSIAN) {
      setLocale(LOCALES.UZBEK);
    } else {
      setLocale(LOCALES.RUSSIAN);
    }
  };

  return (
    <button className={styles.button + ' ' + className} type='button' onClick={onClickSetLocale}>
      <img src={locale === LOCALES.RUSSIAN ? uzFlag : ruFlag} alt='' />
    </button>
  );
};

LangToggle.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired
};

export default LangToggle;
