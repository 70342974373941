import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ConfirmCodeField from '../../components/ConfirmCodeField';
import Page from '../../layout/Page';
import NextButton from '../../components/NextButton';
import TerminalKeyboard from '../../components/TerminalKeyboard';

import { api } from '../../api/api';
import { fetchConfirmCode } from '../../api/fetchConfirmCode';
import { fetchPhone } from '../../api/fetchPhone';
import { formatPhoneNumber } from '../../utils';

import styles from './style.module.css';

import iconDelete from '../../assets/images/icon-delete.svg';

const CODE_MAX_LENGTH = 5;

const ConfirmCode = ({
  locale,
  user,
  setUser,
  setError
}) => {
  const [confirmCode, setConfirmCode] = useState('');
  const [isError, setIsError] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isSendCodeEnabled, setIsSendCodeEnabled] = useState(false);

  const history = useHistory();

  useEffect(() => {
    let timer = null;
    if (!isSendCodeEnabled) {
      timer = setTimeout(() => {
        setIsSendCodeEnabled(true);
      }, user.ttl * 1000 || 30000);
    }

    return () => clearTimeout(timer);
  }, [isSendCodeEnabled]);

  const onNumberChange = (input) => {
    if (confirmCode.length < CODE_MAX_LENGTH) {
      setConfirmCode(confirmCode + input);
    }
  };

  const onDeleteButtonClick = () => {
    if (confirmCode.length > 0 && !isChecking) {
      setConfirmCode(confirmCode.slice(0, confirmCode.length - 1));
      setIsError(false);
    }
  };

  const sendAnotherCode = () => {
    const phone = formatPhoneNumber(user.phone);

    fetchPhone(locale, phone)
      .then((response) => {
        if (response.data.success) {
          if (response.data.data.confirmationCode) {
            console.log(response.data.data.confirmationCode);
            setUser({ ...user, ...{ code: response.data.data.confirmationCode } });
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.success === false) {
          setError(err.response.data.errors[0].message);
        }
      });
    setIsSendCodeEnabled(false);
  };

  const checkConfirmCode = useCallback(() => {
    setIsChecking(true);
    const data = {
      phone: formatPhoneNumber(user.phone),
      code: confirmCode
    };

    fetchConfirmCode(locale, data)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem('mmctoken', response.data.data.token);
          api.defaults.headers.Authorization = response.data.data.token;
          history.push('/name');
        } else {
          setIsError(true);
        }
        setIsChecking(false);
      })
      .catch(err => {
        if (err.response.status === 422) {
          setIsError(true);
        } else if (err.response.data.success === false) {
          setIsError(false);
          setError(err.response.data.errors[0].message);
        }
        setIsChecking(false);
      });
  }, [locale, confirmCode]);

  return (
    <Page
      // title='Введите код из СМС'
      // desc={`Мы отправили код в SMS-сообщении на номер:<br />${user.phone}`}
      title={<FormattedMessage id='confirmTitle' />}
      desc={
        <>
          <FormattedMessage id='confirmDesc' values={{ nl: () => <br />, phone: user.phone }} />
          {
            user.code && <span> код {user.code}</span>
          }
        </>
      }
    >
      <form className={styles.codeForm}>
        <div className={styles.codeWrapper}>
          <button
            className={styles.repeatButton}
            type='button'
            disabled={!isSendCodeEnabled}
            onClick={sendAnotherCode}>
            {/* Отправить код повторно */}
            <FormattedMessage id='buttonSendCode' />
          </button>
          <ConfirmCodeField
            value={confirmCode}
            isError={isError}
            fieldLength={CODE_MAX_LENGTH}
          />
          <button className={styles.deleteButton} type='button' onClick={onDeleteButtonClick}>
            <img src={iconDelete} alt='Удалить' />
          </button>
        </div>
        <TerminalKeyboard
          onChange={onNumberChange}
          isNumeric
        />

        <NextButton
          className={styles.nextButton}
          isDisabled={confirmCode.length !== CODE_MAX_LENGTH}
          onClick={checkConfirmCode}
        >
          Далее
        </NextButton>
      </form>
    </Page >
  );
};

ConfirmCode.propTypes = {
  locale: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
};

export default ConfirmCode;
