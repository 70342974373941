import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import TerminalKeyboard from '../../components/TerminalKeyboard';
import Page from '../../layout/Page';
import NextButton from '../../components/NextButton';
import ErrorMessage from '../../components/ErrorMessage';

import { fetchPhone } from '../../api/fetchPhone';
import { formatPhoneNumber } from '../../utils';

import styles from './style.module.css';

import iconDelete from '../../assets/images/icon-delete.svg';

const START_VALUE = '+7 ';
const MAX_PHONE_VALUE_LENGTH = 16;

const Phone = ({
  locale,
  user,
  setUser,
  setError
}) => {
  const [phoneValue, setPhoneValue] = useState(user.phone || START_VALUE);
  const [isError, setIsError] = useState(false);

  const history = useHistory();

  const onNumberChange = input => {
    if (phoneValue.length < MAX_PHONE_VALUE_LENGTH)
      if (phoneValue.length === 6) {
        setPhoneValue(phoneValue + ' ' + input);
      } else if (phoneValue.length === 10 || phoneValue.length === 13) {
        setPhoneValue(phoneValue + '-' + input);
      } else {
        setPhoneValue(phoneValue + input);
      }
  };

  const onDeleteButtonClick = () => {
    if (phoneValue !== START_VALUE) {
      setPhoneValue(phoneValue.slice(0, phoneValue.length - 1));
      setIsError(false);
    }
  };

  const postPhoneNumber = useCallback(() => {
    const phone = formatPhoneNumber(phoneValue);

    fetchPhone(locale, phone)
      .then((response) => {
        if (response.data.success) {
          if (response.data.data.confirmationCode) {
            console.log(response.data.data.confirmationCode);
            setUser({ ...user, ...{ phone: phoneValue, ttl: response.data.data.ttl, code: response.data.data.confirmationCode } });
          } else {
            setUser({ ...user, ...{ phone: phoneValue, ttl: response.data.data.ttl } });
          }
          history.push('/confirmcode');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.success === false) {
          setError(err.response.data.errors[0].message);
        } else {
          setIsError(true);
        }
      });
  }, [locale, phoneValue]);

  const phoneClassName = classNames(
    styles.phone,
    {
      [styles.error]: isError
    }
  );

  return (
    <Page
      // title='Введите номер телефона'
      // desc='Мы отправим 5-ти значный код на указанный номер для проверки телефона'
      title={<FormattedMessage id='phoneTitle' />}
      desc={<FormattedMessage id='phoneDesc' />}
    >
      <form className={styles.phoneForm}>
        <div className={styles.phoneWrapper}>
          <div className={styles.messageWrapper}>
            {
              isError
                ? <ErrorMessage>
                  {/* Неверный номер телефона, попробуйте еще раз. */}
                  <FormattedMessage id='phoneError' />
                </ErrorMessage>
                : null
            }
            <input className={phoneClassName} type="text" name="phone" id="phone" maxLength={10} value={phoneValue} readOnly />
          </div>
          <button className={styles.deleteButton} type='button' onClick={onDeleteButtonClick}>
            <img src={iconDelete} alt='Удалить' />
          </button>
        </div>
        <TerminalKeyboard
          onChange={onNumberChange}
          isNumeric
        />
        <NextButton
          className={styles.nextButton}
          isDisabled={phoneValue.length !== MAX_PHONE_VALUE_LENGTH}
          onClick={postPhoneNumber}
        />
      </form>
    </Page>
  );
};

Phone.propTypes = {
  locale: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
};


export default Phone;
