import React from 'react';

import PropTypes from 'prop-types';

import Page from '../../layout/Page';
import styles from './style.module.css';

import { FormattedMessage } from 'react-intl';
import NavButton from '../../components/NavButton';

const cities = [
  'Москва',
  'Московская область',
];

const City = ({
  userProfile,
  setUserProfile,
}) => {
  return (
    <Page
      // title='Выберите город, где собираетесь работать'
      title={<FormattedMessage id='cityTitle' />}
    >
      <div className={styles.wrapper}>
        {
          cities.map(item =>
            <NavButton
              className={styles.cityBtn}
              to='/visitdate'
              onClick={() => {
                setUserProfile({ ...userProfile, ...{ city: item } });
              }}
              key={item}
            >
              {item}
            </NavButton>
          )
        }
      </div>
    </Page >
  );
};

City.propTypes = {
  locale: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired,
  setUserProfile: PropTypes.func.isRequired
};

export default City;
