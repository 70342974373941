import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Page from '../../layout/Page';
import NavButton from '../../components/NavButton';

import styles from './style.module.css';

import qrCode from '../../assets/images/qr-code.png';
import googlePlayIcon from '../../assets/images/icon-google-play.svg';
import { useHistory } from 'react-router';

const DELAY = 30;

const PromoPage = ({
  logOut
}) => {
  const history = useHistory();

  useEffect(() => {
    let exitTimeout= null;
    exitTimeout = setTimeout(() => {
      logOut(history);
    }, DELAY * 1000);
    return () => clearTimeout(exitTimeout);
  });

  return (
    <Page>
      <div className={styles.img}>
        <img src={qrCode} alt="Скачайте приложение" width={440} height={440} />
      </div>
      <p className={styles.text}>
        {/* Отсканируйте QR-код и скачайте приложение<br />в&nbsp;<img src={googlePlayIcon} />&nbsp;Google Play. Отслеживайте статусы и важную информацию. */}
        <FormattedMessage id='promoText'  values={{ nl: () => <br />, img: (icon)=> <img src={icon} />, icon: googlePlayIcon }}/>
      </p>

      <NavButton
        to='/'
        type='finish'
        onClick={() => logOut(history)}
      >
        {/* На главную */}
        <FormattedMessage id='buttonToMain' />
      </NavButton>
    </Page >
  );
};

PromoPage.propTypes = {
  logOut: PropTypes.func.isRequired
};

export default PromoPage;
